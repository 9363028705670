import React, { FC } from 'react';
import { Seo } from '../components';
import { PageProps } from 'gatsby';
import { PageLayout } from '../components';
import config from '../../app-config.json';
import * as s from '../style.pages/index.style';
import { Typography } from '@material-ui/core';
import { siteMetadata } from '../../gatsby-config';



const PAGE_TITLE = config.pages.PRIVACY_POLICY.title;
const { WEBSITE_TITLE } = config;

//////////////////////////////////  component ////////////////////////////////
const PrivacyPolicy: FC<PageProps> = ({ }) => {
    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} indexing={false} />
            <s.BodyWrap >

                <Typography variant='h6' component='p' gutterBottom ><b>Organisation Name: {WEBSITE_TITLE}</b></Typography>
                <Typography align='justify' variant='h6' component='p' gutterBottom ><b>Disclaimer</b></Typography>
                <Typography align='justify' >The information contained in this website is for general information purposes only. The information is provided by {WEBSITE_TITLE} and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</Typography>
                <br />
                <Typography align='justify' >Through this website you are able to link to other websites which are not under the control of {WEBSITE_TITLE}. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Every effort is made to keep the website up and running smoothly. However, {WEBSITE_TITLE} takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Privacy Policy and cookies</Typography>
                <Typography align='justify' >{WEBSITE_TITLE} respect your privacy and aim to give you full transparency on how we collect, use and share your personal information. Your privacy is of the utmost importance to us and we’re committed to ensuring we do everything possible to protect your personal data.</Typography>
                <br />
                <Typography align='justify' >The purpose of this privacy policy is to inform users of our site <a href="https://sbqhlondon.co.uk" target="blank" >sbqhlondon.co.uk</a> what information we collect and how we use it. We will only collect the personal data that we feel necessary to help us carry out our services. We will also go into detail on how we use your information, your rights and how you can get in touch.</Typography>
                <br />
                <Typography align='justify' >Throughout this privacy policy, when we refer to ‘we’, ‘us’ or ‘our’, we mean {WEBSITE_TITLE}.</Typography>
                <br /><br />

                {/* <a href="https://stripe.com/docs/security/stripe" target="blank" >Stripe</a> */}
                {/* <a href="https://sbqhlondon.co.uk" target="blank" >sbqhlondon.co.uk</a> */}

                <Typography variant='h4' component='h2' gutterBottom >What is included in this privacy policy?</Typography>
                <s.TypoList type='ul'>
                    <li>Information we collect and how we use it.</li>
                    <li>Non-personal information we collect.</li>
                    <li>Your rights.</li>
                    <li>Confidentiality and security.</li>
                    <li>Accessing your personal data.</li>
                    <li>Contact information.</li>
                    <li>Changes to this privacy policy.</li>
                </s.TypoList>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >What is personal data?</Typography>
                <Typography align='justify' >Personal data is information that relates to an identifiable person who could be identified as an individual based on the information provided such as name or email address. Personal data is regulated by the Data Protection Act 2018. The EU-wide General Data Protection Regulation (GDPR), brought into law on 25 May 2018.</Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >What information do we collect from you?</Typography>
                <Typography align='justify' >We may collect personal information from you when you use our site. All information collected will be kept secure and will only be used for the purposes it was collected for, unless agreed otherwise. All information is collected in accordance with this privacy policy, and Data Protection Laws.</Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Online enquiry forms</Typography>
                <Typography align='justify' >We use online contact forms for on-site enquiries. If you make an enquiry, we’ll collect the following:</Typography>
                <br />
                <s.TypoList type='ul'>
                    <li>Full name – so we know what to call you.</li>
                    <li>Email address – so we can reach you if we can’t get hold of you on the telephone.</li>
                    <li>Message – this allows us to understand why you’re getting in touch so the right person can contact you regarding your enquiry.</li>
                </s.TypoList>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Online payment forms</Typography>
                <Typography align='justify' >For all the payment transactions, we use Stripe a third-party payment gateway service that is responsible for the card details which you have placed to pay Qardan Hasana and will not be kept by {WEBSITE_TITLE} or any other connected person. Those details will be confidential between you and Stripe. Please visit <a href="https://stripe.com/docs/security/stripe" target="blank" >Stripe</a> for any further information.</Typography>
                <br />
                <Typography align='justify' >We will only have access to the following information:</Typography>
                <br />
                <s.TypoList type='ul'>
                    <li>Full name – so we know what to call you.</li>
                    <li>Email address – so we can send you a receipt for your payment.</li>
                    <li>ITS No – so we can include this on your payment receipt.</li>
                </s.TypoList>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Other information we may collect</Typography>
                <Typography align='justify' >From time to time, we may also collect the following information through forms on the site, whether we’re running promotions, recruitment campaigns or customer surveys:</Typography>
                <br />
                <s.TypoList type='ul'>
                    <li>Visits to our website.</li>
                    <li>Enquiry information.</li>
                    <li>Information for recruitment purposes.</li>
                    <li>Information you provide in online surveys or feedback.</li>
                </s.TypoList>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >How do we use your personal data?</Typography>
                <Typography align='justify' >Depending on the reason that we collected your personal data, we may use your information in the following ways:</Typography>
                <br />
                <s.TypoList type='ul'>
                    <li>To respond to an enquiry you made about a service.</li>
                    <li>To respond to an enquiry you made about us.</li>
                    <li>Conduct or analyse market research to help improve future experiences.</li>
                    <li>Assess the performance of our site.</li>
                    <li>Notify you about relevant changes or important information.</li>
                    <li>Check the accuracy of information that we hold for you.</li>
                    <li>Assess your experience with us.</li>
                    <li>Marketing (if opted-in to marketing activity).</li>
                </s.TypoList>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Non-personal data</Typography>
                <Typography align='justify' variant='h6' ><b>Google Analytics</b></Typography>
                <br />
                <Typography align='justify' >We have a tool called Google Analytics installed on our site so that we can monitor performance which allows us to make continuous improvements. This helps us to ensure our customers receive the best experience possible. We use this tool to gather non-personal statistical data such as the following:</Typography>
                <br />
                <s.TypoList type='ul'>
                    <li>Device type.</li>
                    <li>Location.</li>
                    <li>Browsing activity.</li>
                </s.TypoList>
                <br />
                <Typography align='justify' >This information can’t be tracked back to you. For more information on how Google Analytics possesses this information, visit <a href="https://www.google.com/analytics" target="blank" >Google-Analytics</a>.</Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Your rights</Typography>
                <Typography align='justify' >Under the current General Data Protection Regulation, you have the following rights:</Typography>
                <br />
                <s.TypoList type='ul'>
                    <li>Right of access – you have the right to request a copy of the data that we hold about you.</li>
                    <li>Right of rectification – you have a right to correct data that we hold about you that is inaccurate.</li>
                    <li>Right to be forgotten – you have the right to ask for the data we hold about you to be erased from our records.</li>
                    <li>Right to restriction of processing – you have the right to ask for us to retrict the way we process your data.</li>
                    <li>Right of portability – you have the right to have the data we hold about you transferred to another organisation..</li>
                    <li>Right to object – you have the right to object to our processing your data where we are relying on a legitimate interest in order to process it.</li>
                    <li>Right to withdraw your consent – you have the right to withdraw your consent at any time.</li>
                </s.TypoList>
                <br />
                <Typography align='justify' >If you have any questions about our privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</Typography>
                <br />
                <Typography align='justify' ><b>Email us at:</b> <a href={`mailto:${config.pages.CONTACT.details.email}`}>{config.pages.CONTACT.details.email}</a></Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Confidentiality and security</Typography>
                <Typography align='justify' >Confidentiality of your personal data is of the utmost importance to us. We’re committed to protecting the information you’ve provided and we fully comply with Data Protection legislation.</Typography>
                <br />
                <Typography align='justify' >We have a number of organisational processes in place and technical security measures in place to help us keep your data safe. We will hold your personal data for as long as necessary, depending on why it was collected and in accordance with applicable UK laws.</Typography>
                <br />
                <Typography align='justify' >Please note, the transmission of information via the internet cannot be completely guaranteed as completely secure. To make sure we do all we can to keep your data secure, we have several procedures and security features that help to minimise the risk of data breaches or unauthorised access.</Typography>
                <br />
                <Typography align='justify' >For all the payment transactions, we use Stripe a third-party payment gateway service that is responsible for providing support to customers regarding transactions, securely and confidentially. For more information on how Stripe control this information, visit <a href="https://stripe.com/en-gb/privacy#personal-data-definition" target="blank" >Stripe-Privacy</a>.</Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Disclosure of your personal data</Typography>
                <Typography align='justify' >In some circumstances, we may have to disclose your personal data with a carefully selected third-party so that they can provide a service that you’ve requested on our behalf. Any third parties that we use are carefully selected and must adhere to our vigorous data security standard.</Typography>
                <br />
                <Typography align='justify' >We may also disclose your personal data if we believe it is required by law, regulation or other government authority to protect the rights of {WEBSITE_TITLE} or the public. We may also cooperate with law enforcement agencies in official investigations if necessary or requested.</Typography>
                <br /><br />



                <Typography gutterBottom variant='h4' component='h2' >Accessing your personal data</Typography>
                <Typography align='justify' >You may request access to your data, and in some instances, can request for it to be transferred via email. In some instances, you may send us personal information over email. Email is not a secure method of information transmission, and we ask that you understand the risks involved in doing so.</Typography>
                <br />
                <Typography align='justify' >You can also request the correction of any mistakes or to update any information that we hold about you so that our records are accurate.</Typography>
                <br />
                <Typography align='justify' >You also have the right to request that we delete your data from our records, however, we may require to hold a minimal amount to allow us to suppress any communications in the future.</Typography>
                <br />
                <Typography align='justify' >If you’d like to make a request to access your personal data or request that we delete your data from our system, please contact us via phone, email or post.</Typography>
                <br />
                <Typography align='justify' ><b>Email us at:</b> <a href={`mailto:${config.pages.CONTACT.details.email}`}>{config.pages.CONTACT.details.email}</a></Typography>
                <br /><br />


            </s.BodyWrap>
        </PageLayout>
    )
}

export default PrivacyPolicy;
